import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-8 md:py-10 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative mt-8`;

const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 -mt-10 mb-10 md:mt-0`, // Уменьшение верхнего отступа
    props.textOnLeft ? tw`md:mr-8 lg:mr-10 md:order-first` : tw`md:ml-8 lg:ml-10 md:order-last`
]);


const TextContent = tw.div`lg:py-8 text-center md:text-left -mt-12`;


const Subheading = styled(SubheadingBase)`
  ${tw`text-center md:text-left`}
  color: #0ABD19;
`;

const Heading = tw(
    SectionHeading
)`mt-6 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight `;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-col max-w-xs lg:max-w-none w-full`;

const Feature = tw.div`mt-6 lg:mt-4 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`flex items-center justify-center w-12 h-12 text-white text-lg font-semibold rounded-full`}
`;

const FeatureHeading = tw.div`ml-3 font-bold text-xl`;

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
    tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
    props.buttonRounded && tw`rounded-full`
]);


const StepNumber = styled.div`
    ${tw`font-semibold text-4xl leading-none text-[#0ABD19]`}
    min-width: 80px;
`;



const defaultFeatures = [
    {
        Icon: BriefcaseIcon,
        title: "Professionalism",
        description: "We have the best professional marketing people across the globe just to work with you.",
        iconContainerCss: tw`bg-teal-300 text-teal-800`
    },
    {
        Icon: MoneyIcon,
        title: "Affordable",
        description: "We promise to offer you the best rate we can - at par with the industry standard.",
        iconContainerCss: tw`bg-red-300 text-red-800`
    }
];

export default ({
                    subheading = "",
                    heading = (
                        <>
                            Designed & Developed by <span tw="text-primary-500">Professionals.</span>
                        </>
                    ),
                    textOnLeft = true,
                    features = null,

                }) => {
    if (!features) features = defaultFeatures;

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Features>
                        {features.map((feature, index) => (
                            <Feature key={index}>
                                <FeatureHeadingContainer>
                                    <FeatureIconContainer>
                                        <StepNumber>{(index + 1).toString().padStart(2, '0')}</StepNumber>
                                    </FeatureIconContainer>
                                    <FeatureHeading>{feature.title}</FeatureHeading>
                                </FeatureHeadingContainer>
                                <FeatureDescription>{feature.description}</FeatureDescription>
                            </Feature>
                        ))}
                    </Features>
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Subheading>{subheading}</Subheading>
                        <Heading>{heading}</Heading>
                        <Description>
                            <span style={{ color: "#0ABD19" }}>Eparcel</span> - Ваш надежный мейлфорвард — с собственными методами доставки и удобными автоматизированными складами.
                        </Description>
                        <Description>
                            <span style={{ color: "#0ABD19" }}>Доставка</span> товаров из-за границы — наша основная деятельность, сотни тысяч клиентов из Казахстана получают свои покупки благодаря нам.
                        </Description>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
