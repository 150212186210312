import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../FireBaseConfig';
import HeaderContainer from 'components/headers/WarehouseAccountingHeader';
import Sidebar from 'WarehouseAccounting/components/Sidebar';
import FormProcessing from '../components/FormsForEditing/FormProcessing';
import FormProducts from '../components/FormsForEditing/FormProducts';
import FormReceiver from '../components/FormsForEditing/FormReceiver';
import FormProfile from '../components/FormsForEditing/FormProfile';
import FormPayment from '../components/FormsForEditing/FormPayment';
import FormShipping from '../components/FormsForEditing/FormShipping';
import styled, { createGlobalStyle } from 'styled-components/macro';
import tw from 'twin.macro';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'SFUIText';
        font-weight: 300;
        font-style: normal;
    }

    body, html {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'SFUIText', sans-serif;
    }
`;

const Container = styled.div`
    ${tw`min-h-screen`}
    display: flex;
    flex-direction: column;
`;

const MainContent = styled.div`
    ${tw`flex flex-col lg:flex-row`}
    flex-grow: 1;
`;

const Content = styled.div`
    ${tw`flex-grow p-8`}
    display: flex;
    flex-direction: column;
`;

const Heading = styled.h1`
    ${tw`text-green-500 font-semibold text-xl`}
`;

const Divider = styled.div`
    ${tw`bg-gray-300`}
    height: 1px;
    width: 100%;
    margin: 20px 0;
`;

const TabsContainer = styled.div`
    ${tw`flex mb-6 mt-4`}
    border-bottom: ${({ hideBorder }) => (hideBorder ? "none" : "1px solid #e5e5e5")};
`;

const Tab = styled.div`
    ${tw`py-4 px-4 cursor-pointer relative`}
    border-top: ${({ isActive }) => (isActive ? "1px solid #e5e5e5" : "none")};
    border-left: ${({ isActive }) => (isActive ? "1px solid #e5e5e5" : "none")};
    border-right: ${({ isActive }) => (isActive ? "1px solid #e5e5e5" : "none")};
    border-bottom: ${({ isActive }) => (isActive ? "none" : "1px solid #e5e5e5")};
    background-color: ${({ isActive }) => (isActive ? "#ffffff" : "transparent")};
    z-index: ${({ isActive }) => (isActive ? "1" : "0")};
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
`;

const Filler = styled.div`
    ${tw`flex-grow`}
    border-bottom: 1px solid #e5e5e5;
`;

const ExpectedParcelsPage = () => {
    const { parcelId } = useParams();
    const [parcelData, setParcelData] = useState(null);
    const [profileData, setProfileData] = useState(null); // Данные профиля пользователя
    const [activeTab, setActiveTab] = useState("Обработка посылки");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchParcelData = async () => {
            try {
                const q = query(collection(db, 'parcels'), where('id', '==', parcelId));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const parcelDoc = querySnapshot.docs[0].data();
                    console.log('Fetched Parcel Data:', parcelDoc);
                    setParcelData(parcelDoc);

                    // Получаем userId и загружаем данные пользователя
                    if (parcelDoc.userId) {
                        fetchUserProfile(parcelDoc.userId);
                    }
                } else {
                    console.error("No such document!");
                }
            } catch (error) {
                console.error("Error fetching parcel data:", error);
            } finally {
                setLoading(false);
            }
        };

        const fetchUserProfile = async (userId) => {
            try {
                const q = query(collection(db, 'users'), where('userId', '==', userId));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const userProfileDoc = querySnapshot.docs[0].data();
                    console.log('Fetched User Profile Data:', userProfileDoc);
                    setProfileData(userProfileDoc);
                } else {
                    console.error("No such user profile!");
                }
            } catch (error) {
                console.error("Error fetching user profile data:", error);
            }
        };

        if (parcelId) {
            fetchParcelData();
        }
    }, [parcelId]);

    const renderForm = () => {
        if (!parcelData) {
            return <p>No parcel data found</p>;
        }

        switch (activeTab) {
            case "Обработка посылки":
                return <FormProcessing parcelData={parcelData} />;
            case "Товары в посылке":
                return <FormProducts productsData={parcelData.products} />;
            case "Получатель":
                return <FormReceiver receiver={parcelData?.receiver} />;
            case "Профиль":
                return <FormProfile profile={profileData} />; // Передаем данные профиля
            case "Оплата":
                return <FormPayment paymentData={parcelData?.payment} />;
            case "Доставка по России":
                return <FormShipping shippingData={parcelData?.shipping} />;
            default:
                return null;
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <Container>
            <GlobalStyle />
            <HeaderContainer />
            <MainContent>
                <Sidebar />
                <Content>
                    <Heading>Редактирование посылки</Heading>
                    <Divider />
                    <TabsContainer hideBorder={activeTab !== ""}>
                        <Tab isActive={activeTab === "Обработка посылки"} onClick={() => setActiveTab("Обработка посылки")}>
                            Обработка посылки
                        </Tab>
                        <Tab isActive={activeTab === "Товары в посылке"} onClick={() => setActiveTab("Товары в посылке")}>
                            Товары в посылке
                        </Tab>

                        <Tab isActive={activeTab === "Получатель"} onClick={() => setActiveTab("Профиль")}>
                            Получатель
                        </Tab>
                        {/**/}
                        <Tab isActive={activeTab === "Оплата"} onClick={() => setActiveTab("Оплата")}>
                            Оплата
                        </Tab>
                        <Tab isActive={activeTab === "Доставка по России"} onClick={() => setActiveTab("Доставка по России")}>
                            Доставка по Казахстану
                        </Tab>
                        <Filler />
                    </TabsContainer>

                    {renderForm()}
                </Content>
            </MainContent>
        </Container>
    );
};

export default ExpectedParcelsPage;