import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import tw from "twin.macro";
import HeaderContainer from "components/headers/WarehouseAccountingHeader"; // Import the header component
import { ReactComponent as SearchIcon } from "images/search-icon.svg";
import Printer from "images/icon/Printerr.svg";
import Sidebar from "WarehouseAccounting/components/Sidebar";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../FireBaseConfig';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'SFUIText';
        font-weight: 300;
        font-style: normal;
    }

    body, html {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'SFUIText', sans-serif;
    }
`;

const Container = styled.div`
    ${tw`min-h-screen`}
    display: flex;
    flex-direction: column;
`;

const MainContent = styled.div`
    ${tw`flex flex-col lg:flex-row`}
    flex-grow: 1;
`;

const Icon = styled.img`
    ${tw`mr-1`} /* Adjusted the margin to ensure the text is closer to the icon */
    width: 25px;  // Increased the size of the icon
    height: 25px; // Increased the size of the icon
`;

const Content = styled.div`
    ${tw`flex-grow p-8`}
    display: flex;
    flex-direction: column;
`;

const Heading = styled.h1`
    ${tw`text-green-500 font-semibold text-xl`}
`;

const Divider = styled.div`
    ${tw`bg-gray-300`}
    height: 1px;
    width: 100%;
    margin: 20px 0;
`;

const Form = styled.div`
    ${tw`flex items-center space-x-6`} /* Increased the spacing between inputs */
    gap: 1rem;
`;

const Label = styled.label`
    ${tw`text-sm font-semibold mb-4`}
    color: #d3d3d3;
`;

const InputContainer = styled.div`
    ${tw`flex flex-col relative items-center`}
    flex: 1;
`;

const Input = styled.input`
    ${tw`border-2 rounded-lg p-3 w-full text-gray-600 mt-4 pl-10`} /* Adjusted padding for icon */
    border-color: #D3D3D3;
    width: 96%;
    &:hover, &:focus {
        border-color: #0ABD19;
    }
`;

const SearchIconContainer = styled.div`
    ${tw`absolute inset-y-0 left-0 flex items-center pl-3 mt-4 `}
    pointer-events: none;
`;

const InfoText = styled.p`
    ${tw`text-gray-600 text-base items-start ml-2 font-bold `}
    color: #999999; /* Light grey color */
`;

const LabelsContainer = styled.div`
    ${tw`grid grid-cols-12 gap-0 p-4`}
`;

const DataGrid = styled.div`
    ${tw`grid grid-cols-12 gap-0 bg-white p-4 rounded-md`}
    margin-top: 0px;
`;

const DataCell = styled.div`
    ${tw`p-2`}
    border: 1px solid #d3d3d3;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 29px;
    height: 40px; /* Reduced height */
`;
const Link = styled.a`text-decoration: none`
const CellLabel = styled.span`
    ${tw`font-semibold text-sm text-gray-800`}
`;

const PrintIcon = styled.img`
    ${tw`w-5 h-5 cursor-pointer`}
`;

const ProccesingParcel = () => {
    const [parcels, setParcels] = useState([]);

    useEffect(() => {
        const fetchParcels = async () => {
            try {
                const q = query(collection(db, "parcels"), where("status", "==", "На складе"));
                const querySnapshot = await getDocs(q);
                const parcelsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                console.log("Fetched parcels:", parcelsData); // Вывод данных в консоль
                setParcels(parcelsData);
            } catch (error) {
                console.error("Error fetching parcels:", error);
            }
        };

        fetchParcels();
    }, []);

    return (
        <Container>
            <GlobalStyle />
            <HeaderContainer />
            <MainContent>
                <Sidebar />
                <Content>
                    <Heading>Обработать посылку</Heading>
                    <Divider />
                    <Form>
                        <InputContainer>
                            <SearchIconContainer>
                                <SearchIcon />
                            </SearchIconContainer>
                            <Input placeholder="Search" />
                        </InputContainer>
                    </Form>
                    <InfoText>Общее количество посылок: {parcels.length}</InfoText>
                    <LabelsContainer>
                        <CellLabel style={{ gridColumn: 'span 1' }}>Shelf no</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 1' }}>№ заявки</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 1' }}>Дата приема</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 3' }}>ID клиента</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 4' }}>Tracking ID</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 2' }}>Print</CellLabel>
                    </LabelsContainer>
                    <DataGrid>
                        {parcels.map((parcel, index) => (
                            <React.Fragment key={index}>
                                <DataCell style={{ gridColumn: 'span 1' }}>
                                    {parcel.shelf || "Не обработана"}
                                </DataCell>
                                <Link href={`/EditingParcel/${parcel.id}`}>
                                    <DataCell style={{ gridColumn: 'span 1' }}>
                                        {parcel.id || "N/A"}
                                        <br />
                                        {parcel.status || "N/A"}
                                    </DataCell>
                                </Link>
                                <DataCell style={{ gridColumn: 'span 1' }}>
                                    {parcel.dateReceived || "Нет даты"} {/* Дата приема */}
                                </DataCell>
                                <DataCell style={{ gridColumn: 'span 3' }}>
                                    {parcel.userId || "Нет ID клиента"}
                                </DataCell>
                                <DataCell style={{ gridColumn: 'span 4' }}>
                                    {parcel.trackingNumber || "Нет Tracking ID"}
                                </DataCell>
                                <DataCell style={{ gridColumn: 'span 2' }}>
                                    <PrintIcon src={Printer} alt="Print" />
                                </DataCell>
                            </React.Fragment>
                        ))}
                    </DataGrid>

                </Content>
            </MainContent>
        </Container>
    );
};

export default ProccesingParcel;
