import {MdDelete} from "react-icons/md";
import plusIcon from "../images/plus.png";
import React, {useEffect, useState} from "react"
import tw, {styled} from "twin.macro";
import {ExpecteLink} from "../components/misc/Headings";
import {useNavigate} from "react-router-dom";
import {collection, deleteDoc, doc, getDocs, getFirestore, query, where} from "firebase/firestore";
import {auth} from "../FireBaseConfig";


const FormContainer = styled.div`
    ${tw`flex justify-center items-center flex-grow`};
`;

const HighlightedText = styled.span`
    ${tw`text-primary-500`};
    color: #0ABD19;
`;
const Container = styled.div`
    ${tw`w-full max-w-6xl p-8 bg-white shadow-lg rounded-lg border`};
    border: 2px solid #1BA557;
    border-radius: 15px;
    padding: 40px;
    display: grid;
    margin-top: 0px;
    grid-template-columns: 1fr 1fr; /* Two columns */
    column-gap: 60px; /* Bigger gap between columns for better spacing */
`;


const CardsContainer = styled.div`
    ${tw`grid grid-cols-1 md:grid-cols-2 gap-10 max-w-screen-xl mx-auto mb-8`}

`;
const StepTitle = tw(ExpecteLink)`w-full mt-2 mb-4`;
const Card = styled.div`
    ${tw`p-4 rounded-lg flex flex-col`}
    border-radius: 20px;
    border: 3px solid #869589;
    width: 250px; // Adjusted width to be smaller
    height: 150px; // Adjusted height to be smaller
    box-sizing: border-box;
`;

const CheckboxWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;
const Checkbox = styled.input.attrs({type: 'checkbox'})`
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #0ABD19;
    border-radius: 4px;
    cursor: pointer;
    position: relative;

    &:checked {
        background-color: #0ABD19;
    }

    &:checked::before {
        content: '\\2713'; /* Unicode for checkmark */
        position: absolute;
        top: 0;
        left: 3px;
        font-size: 14px;
        color: white;
    }
`;

const CardTitle = styled.h2`
    ${tw`text-sm font-bold text-black`} // Adjusted text size to be smaller
`;

const CardText = styled.p`
    ${tw`text-xs font-medium text-black`} // Adjusted text size to be smaller
`;
const EditLink = styled.a`
    ${tw`mt-2 text-xs text-gray-700`}
    cursor: pointer;
`;
const DeleteIcon = styled.button`
    ${tw`ml-auto `}
    background: none;
    border: none;
    cursor: pointer;
    margin-top: -20px;

    &:hover {
        color: red;
    }
`;
const BottomButton = styled.button`
    ${tw`w-auto bg-green-500 text-white font-bold rounded-lg flex items-center justify-center leading-none focus:outline-none transition duration-300`};
    background-color: #0ABD19;
    border: none;
    width: 220px;
    height: 40px;

    &:hover, &:focus {
        transform: scale(1.1);
    }
`;
const Icon = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 15px;
`;


const Step2 = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [recipients, setRecipients] = useState([]);
    const [selectedRecipients, setSelectedRecipients] = useState({});

    useEffect(() => {
        const fetchRecipients = async () => {
            const db = getFirestore();
            const user = auth.currentUser;
            if (user) {
                const q = query(collection(db, "recipients"), where("userId", "==", user.uid));
                const querySnapshot = await getDocs(q);
                const fetchedRecipients = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
                setRecipients(fetchedRecipients);
            }
            setLoading(false);
        };

        fetchRecipients();
    }, []);

    const [showFirstImage, setShowFirstImage] = useState(true);






// Call the function with your parcel ID



// Убедитесь, что этот вызов находится в useEffect, который будет срабатывать при изменении id



    useEffect(() => {
        const interval = setInterval(() => {
            setShowFirstImage((prev) => !prev);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const handleEdit = (recipient) => {
        navigate('/RecipientsForm', {state: {recipient}});
    };

    const handleDelete = async (recipientId) => {
        try {
            const db = getFirestore();
            await deleteDoc(doc(db, "recipients", recipientId));
            setRecipients(recipients.filter(recipient => recipient.id !== recipientId));
        } catch (error) {
            console.error("Error deleting recipient: ", error);
        }
    };
    const handleCheckboxChange = (recipientId) => {
        setSelectedRecipients(prevState => ({
            ...prevState,
            [recipientId]: !prevState[recipientId]
        }));
    };



    return(
<FormContainer>
    <div style={{width: '100%', maxWidth: '1280px'}}>
        <StepTitle active={true}>
            <HighlightedText>Шаг 2. </HighlightedText>Укажите получателя
        </StepTitle>
        <Container>
            {/* Wrapper for Cards and Button */}
            <div
                style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                <CardsContainer style={{width: '100%', justifyContent: 'center'}}>
                    {recipients.map((recipient) => (
                        <Card key={recipient.id} style={{marginBottom: '20px'}}>
                            <CheckboxWrapper>
                                <Checkbox
                                    checked={!!selectedRecipients[recipient.id]}
                                    onChange={() => handleCheckboxChange(recipient.id)}
                                />
                            </CheckboxWrapper>

                            <CardTitle>{recipient.name} {recipient.surname}</CardTitle>
                            <CardText>{recipient.phone}</CardText>
                            <EditLink onClick={() => handleEdit(recipient)}>Редактировать
                                данные</EditLink>
                            <DeleteIcon
                                onClick={() => handleDelete(recipient.id)}><MdDelete/></DeleteIcon>
                        </Card>
                    ))}
                </CardsContainer>


                <div style={{display: 'flex', justifyContent: 'start', width: '100%'}}>
                    <BottomButton style={{marginLeft: '0'}}>
                        <Icon src={plusIcon} alt="Plus Icon"/>
                        Добавить получателя
                    </BottomButton>
                </div>
            </div>
        </Container>
    </div>
</FormContainer>
);
};

export default Step2;