    import React, { useEffect, useState } from "react";
    import styled from "styled-components";
    import tw from "twin.macro";
    import { ExpecteLink } from "../components/misc/Headings";
    import { useParams } from "react-router-dom";
    import { collection, getDocs, query, where } from "firebase/firestore";
    import { db } from "../FireBaseConfig";

    const FormContainer = styled.div`
        ${tw`flex justify-center items-center flex-grow`};
    `;
    const Container = styled.div`
        ${tw`w-full max-w-6xl p-8 bg-white shadow-lg rounded-lg border`};
        border: 2px solid #1BA557;
        border-radius: 15px;
        padding: 40px;
        display: grid;
        margin-top: 0px;
        grid-template-columns: 1fr 1fr; /* Two columns */
        column-gap: 60px; /* Bigger gap between columns for better spacing */
    `;
    const HighlightedText = styled.span`
        ${tw`text-primary-500`};
        color: #0ABD19;
    `;
    const StepTitle = tw(ExpecteLink)`w-full mt-2 mb-4`;

    const Step3Backend = () => {
        useEffect(() => {
            // Инициализация виджета CDEK
            new window.CDEKWidget({
                from: {
                    country_code: 'RU',
                    city: 'Москва',
                    postal_code: '101000',
                    address: 'ул. Тверская, д. 1',
                },
                root: 'cdek-map',
                apiKey: '5c3971a4-b56c-41ba-bb60-2aef6e535a26',
                canChoose: true,
                servicePath: 'https://www.eparcel.kz/service.php',
                hideFilters: {
                    have_cashless: false,
                    have_cash: false,
                    is_dressing_room: false,
                    type: false,
                },
                hideDeliveryOptions: {
                    office: false,
                    door: false,
                },
                debug: false,
                goods: [
                    {
                        width: 10,
                        height: 10,
                        length: 10,
                        weight: 10,
                    },
                ],
                defaultLocation: 'Астана',
                lang: 'rus',
                currency: 'KZT',
                tariffs: {
                    office: [234, 136, 138],
                    door: [233, 137, 139],
                },
                onReady() {
                    console.log('Виджет загружен');
                },
                    onCalculate(tariffs, address) {
                        console.log('Расчет стоимости доставки произведен');
                        console.log('Рассчитанные тарифы:', tariffs);
                        console.log('Адрес для доставки:', address);

                        if (tariffs.door && tariffs.door.length > 0) {
                            tariffs.door.forEach((tariff) => {
                                console.log(`Тариф до двери: ${tariff.tariff_name}, Стоимость: ${tariff.delivery_sum} RUB, Срок: ${tariff.period_min}-${tariff.period_max} дней`);
                            });
                        } else {
                            console.log('Не удалось получить тарифы для доставки до двери');
                        }
                    },


                onChoose(deliveryType, tariff, address) {
                    console.log(`Выбран тип доставки: ${deliveryType}`);
                    console.log('Выбранный тариф:', tariff);
                    console.log('Адрес:', address);
                },
            });
        }, []);

        return (
            <FormContainer>
                <div style={{ width: '100%', maxWidth: '1280px' }}>
                    <StepTitle active={true}>
                        <HighlightedText>Шаг 3. </HighlightedText>Выберите способ доставки
                    </StepTitle>
                    <Container>
                        <div id="cdek-map" style={{ width: "800px", height: "600px", overflow: "hidden" }}></div>
                    </Container>
                </div>
            </FormContainer>
        );
    };

    export default Step3Backend;
