import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
const PopupContainer = styled.div`
    ${tw`fixed top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50`}
`;

const PopupContent = styled.div`
    ${tw`bg-white p-6 rounded-lg shadow-lg`};
    width: 500px;
`;

const Title = styled.h2`
    ${tw`text-2xl font-bold mb-4 text-center`}
`;

const Message = styled.p`
    ${tw`text-black`};
    max-width: 70%;
`;

const NotificationCard = styled.div`
    ${tw`bg-gray-100 p-4 rounded-lg flex justify-between items-center mb-2 shadow`};
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0; /* Изменение цвета фона при наведении */
    }
`;

const CloseButton = styled.button`
    ${tw`mt-4 py-3 bg-green-500 text-white rounded`};
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    background-color: #0ABD50;
    border: none; /* Убираем границу */
`;

const DateTime = styled.span`
    ${tw`text-gray-600 text-sm`};
`;

const NotificationPopup = ({ notifications, onClose }) => {
    return (
        <PopupContainer>
            <PopupContent>
                <Title>Уведомления</Title>
                {notifications.length === 0 ? (
                    <Message>Нет новых уведомлений.</Message>
                ) : (
                    notifications.map((notification, index) => (
                        <NotificationCard key={index}>
                            <Message>{notification.message}</Message>
                            <DateTime>{new Date(notification.timestamp).toLocaleString()}</DateTime> {/* Отображение даты и времени */}
                        </NotificationCard>
                    ))
                )}
                <CloseButton onClick={onClose}>Закрыть</CloseButton>
            </PopupContent>
        </PopupContainer>
    );
};

export default NotificationPopup;
