import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide1 from "images/Slide1.svg"; // Ensure correct path
import Slide2 from "images/Slide2.svg"; // Ensure correct path

const Container = tw.div`relative mx-0 mt-0`; // Убираем все внешние отступы
const TwoColumn = tw.div`flex flex-col lg:flex-row items-start lg:items-center`; // Выравниваем содержимое по центру

const LeftColumn = styled.div`
    ${tw`w-full lg:w-3/5 py-0`} // Убираем верхний и нижний отступы
`;

const RightColumn = styled.div`
    ${tw`w-full lg:w-1/2 p-4`} // Убираем лишние отступы
`;

const Content = styled.div`
    ${tw`flex flex-col lg:items-start items-center text-left`}
    margin-top: -250px; // Устанавливаем отрицательный отступ, чтобы поднять текст выше
`;
const Heading = tw.h1`text-3xl sm:text-4xl md:text-5xl font-bold mb-4 leading-tight text-center lg:text-left`; // Убираем отступы справа, текст выравниваем влево

const Paragraph = tw.p`max-w-full my-4 text-base lg:text-lg leading-relaxed text-center lg:text-left`; // Убираем фиксированную ширину и делаем текст на всю ширину

const Actions = styled.div`
    ${tw`mt-4 mb-8 lg:mb-0`} // Убираем лишние отступы
    button {
        ${tw`w-[200px] sm:w-[250px] md:w-[300px] bg-green-500 text-white font-bold my-4 sm:my-2 rounded-full py-3 flex items-center justify-center focus:outline-none transition duration-300`}
        background-color: #0ABD19;
        &:hover, &:focus {
            transform: scale(1.05);
            background-color: #0ABD50;
        }
    }
`;

const StyledSlider = styled(Slider)`
    ${tw`mt-4`} // Уменьшаем отступ сверху
`;
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
};

export default ({
                    heading = "Наш склад в США",
                    description = "Склад в США расположен в безналоговом штате Делавэр, на адрес которого покупатели из Казахстана могут отправить свои товары, купленные в онлайн-магазинах, а их мы доставим на Ваш адрес в Казахстан. Надежная доставка товаров из США до Вашего дома – полностью на нас.",
                }) => {
    return (
        <Container>
            <TwoColumn>
                <LeftColumn>
                    <Content>
                        <Heading>{heading}</Heading>
                        <Paragraph>{description}</Paragraph>
                        <Actions>
                            <button>Получить адрес доставки</button>
                        </Actions>
                    </Content>
                </LeftColumn>
                <RightColumn>
                    <StyledSlider {...settings}>
                        <div>
                            <img src={Slide1} alt="Warehouse slide 1"  />
                        </div>
                    </StyledSlider>
                </RightColumn>
            </TwoColumn>
        </Container>
    );
};