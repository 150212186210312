import React, { useEffect, useState, useCallback , useMemo} from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import usFlag from '../../images/us-icon.png';
import trFlag from '../../images/tr-icon.png';
import Box from 'images/icon/BeautifullTinyBox.svg';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, limit } from 'firebase/firestore';
import {db} from "../../FireBaseConfig"
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const ParcelCard = styled.div`
    ${tw`bg-white rounded-lg p-3 mb-4`}
    border: 1px solid #d9d9d9;
    cursor: pointer;
    border-radius: 15px;
    padding: 16px;
    position: relative;
`;
const CardHeaderLink = styled.a`
    ${tw`text-white text-sm font-medium relative flex-shrink-0 h-auto py-10 no-underline`}
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;

    @media (max-width: 768px) {
        writing-mode: horizontal-tb;
        transform: rotate(0);
        padding: 10px;
        border-left: none;
        border-bottom: 2px solid #00bc00;
    }
`;
const ParcelHeading = styled.div`
    ${tw`flex items-center justify-between`}
`;

const ParcelColumn = styled.div`
    ${tw`flex flex-col`}
    margin-left: 4%;
    display: flex;
`;

const ColumnTitle = styled.h4`
    ${tw`text-xs font-medium inline-block`}
    margin-right: 0.5rem;
    line-height: 0.1;
`;

const ColumnText = styled.span`
    ${tw`text-xs text-gray-600 inline-block`}
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
`;

const ParcelID = styled.div`
    ${tw`flex items-center`}
    width: 41%;
    color: #0ABD19;

    img {
        width: 25px;
        height: auto;
        margin-right: 8px;
    }
`;

const SectionTitle = styled.h3`
    ${tw`font-medium text-sm justify-start items-start`}
    width: 34%;
    color: #2D2D2D;
`;

const SectionThirdTitle = styled.h3`
    ${tw`font-semibold text-sm`}
    width: 25%;
    color: #2D2D2D;
`;

const LeftAlignedParcelColumn = styled(ParcelColumn)`
    margin-right: 10%;
`;

const RightAlignedParcelColumn = styled(ParcelColumn)`
    margin-left: 7%;
`;

const ExpandButton = styled.button`
    ${tw`ml-2 font-medium text-sm py-1 px-3 rounded-full flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    border: none;
    color: #0ABD19;
    font-size: 18px;
    background-color: transparent;
    &:hover, &:focus {
        transform: scale(1.1);
    }
`;

const ExpandedInfo = styled.div`
    ${tw`mt-4 ml-12`}
`;

const ParcelInfo = styled.div`
    ${tw`flex justify-between items-start mt-2`}
`;

const TableContainer = styled.div`
    ${tw`w-full mt-4`}
`;

const TableRow = styled.div`
    ${tw`flex justify-between items-center py-2`}
    border-bottom: 1px solid #e5e7eb;
`;

const TableRowFirst = styled.div`
    ${tw`flex justify-between items-center py-2`}
`;

const TableHeader = styled(TableRow)`
    border-bottom: 1px solid #e5e7eb;
`;

const TableCell = styled.div`
    ${tw`text-sm text-gray-600`}
    width: 20%;
    text-align: left;
`;

const TotalCostCell = styled.div`
    ${tw`text-sm`}
    width: 20%;
    color: #0ABD19;
    text-align: left;
`;

const TableCellFirst = styled.div`
    ${tw`text-sm text-gray-600`}
    width: 40%;
    text-align: left;
`;

const TotalRow = styled.div`
    ${tw`flex justify-between items-center py-2`}
`;

const TotalCell = styled.div`
    ${tw`text-sm font-bold text-green-600`}
    text-align: right;
`;

const ProductsTitle = styled.h4`
    ${tw`font-semibold text-sm`}
    color: #2D2D2D;
    margin-bottom: 0.5rem;
`;

const EditLink = styled.a`
    ${tw`text-sm text-green-600`}
    margin-left: auto;
`;

const GreenStrip = styled.div`
    ${tw`absolute top-0 right-0 h-full`}; /* Убрано скругление */
    width: 40px;
    background-color: #00bc00;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
`;


const SendParcelLink = styled.a`
    ${tw`text-green-600 text-sm font-medium`}
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-bottom: 4px;
    border-bottom: 1px dashed #00bc00;
    margin-top: 10px;
`;

const SendParcelIcon = styled.img`
    width: 20px;
    height: auto;
    margin-right: 5px;
`;
const ParcelCardComponent = React.memo(({ parcel, expandedParcel, onParcelClick }) => {
    const [parcels, setParcels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const [hasFetched, setHasFetched] = useState(false);
    const navigate = useNavigate();

    // Fetch user ID
    const fetchUserId = useCallback(async () => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setUserId(userData.userId);
            }
        }
    }, []);

    useEffect(() => {
        fetchUserId();
    }, [fetchUserId]);

    // Fetch parcels
    const fetchParcels = useCallback(async () => {
        if (!userId || hasFetched) return;
        setLoading(true);
        setParcels([]); // Очищаем состояние перед загрузкой

        try {
            const q = query(
                collection(db, 'parcels'),
                where("userId", "==", userId),
                where("status", "in", ["На складе", "Обработана"])
            );
            const querySnapshot = await getDocs(q);
            const fetchedParcels = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Фильтруем уникальные посылки
            const uniqueParcels = Array.from(new Map(fetchedParcels.map(parcel => [parcel.id, parcel])).values());
            setParcels(uniqueParcels); // Обновляем состояние с уникальными посылками
            setHasFetched(true);
        } catch (error) {
            console.error("Error fetching parcels:", error);
        } finally {
            setLoading(false);
        }
    }, [userId, hasFetched]);


    useEffect(() => {
        if (userId) {
            fetchParcels();
        }
    }, [userId, fetchParcels]); // Добавляем fetchParcels в зависимости

    const memoizedParcels = useMemo(() => parcels, [parcels]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (memoizedParcels.length === 0) {
        return <p>Нет посылок со статусом "На складе" или "Обработано".</p>;
    }

    return (
        <>
            {memoizedParcels.map((parcel) => (

                <ParcelCard key={parcel.id}>
                    <ParcelHeading onClick={() => onParcelClick(parcel)}>
                        <ParcelID>
                            <img src={parcel.warehouse === 'США' ? usFlag : trFlag} alt="Country Flag" />
                            Посылка #{parcel.id}
                            <ExpandButton>
                                {expandedParcel === parcel.id ? <IoIosArrowUp /> : <IoIosArrowDown />}
                            </ExpandButton>
                        </ParcelID>
                        <SectionTitle>{parcel.parcelName}</SectionTitle>
                        <SectionThirdTitle>Информация о посылке</SectionThirdTitle>
                    </ParcelHeading>
                    <ParcelInfo>
                        <RightAlignedParcelColumn>
                            <div>
                                <ColumnTitle>Создано:</ColumnTitle>
                                <ColumnText>{parcel.createdAt}</ColumnText>
                            </div>
                            <div>
                                <ColumnTitle>Статус:</ColumnTitle>
                                <ColumnText>{parcel.status}</ColumnText>
                            </div>
                            {parcel.status === "Обработана" && (
                                <SendParcelLink onClick={() => navigate(`/SendParcelForm/${parcel.id}`)}>
                                    <SendParcelIcon src={Box} alt="Send Parcel Icon" />
                                    Отправить посылку
                                </SendParcelLink>
                            )}
                        </RightAlignedParcelColumn>
                        <RightAlignedParcelColumn>
                            <div>
                                <ColumnTitle>Магазин:</ColumnTitle>
                                <ColumnText>{parcel.storeName}</ColumnText>
                            </div>
                            <div>
                                <ColumnTitle>Входящий трек:</ColumnTitle>
                                <ColumnText>{parcel.trackingNumber}</ColumnText>
                            </div>
                        </RightAlignedParcelColumn>
                        <LeftAlignedParcelColumn>
                            <div>
                                <ColumnTitle>Общее количество товаров:</ColumnTitle>
                                <ColumnText>{parcel.totalQuantity}</ColumnText>
                            </div>
                            <div>
                                <ColumnTitle>Стоимость товара(ов):</ColumnTitle>
                                <ColumnText>{parcel.totalCost} $</ColumnText>
                            </div>
                        </LeftAlignedParcelColumn>
                    </ParcelInfo>
                    {expandedParcel === parcel.id && (
                        <ExpandedInfo>
                            <TableContainer>
                                <TableRowFirst>
                                    <ProductsTitle>Товары</ProductsTitle>
                                    <EditLink href={`/edit/${parcel.id}`}>Редактировать посылку</EditLink>
                                </TableRowFirst>
                                <TableHeader>
                                    <TableCellFirst>Название товара</TableCellFirst>
                                    <TableCell>Цена за штуку</TableCell>
                                    <TableCell>Количество</TableCell>
                                    <TableCell>{(parseFloat(parcel.totalCost)).toFixed(2)} $</TableCell>
                                </TableHeader>
                                {parcel.products.map((product, index) => (
                                    <TableRow key={index}>
                                        <TableCellFirst>{product.productName}</TableCellFirst>
                                        <TableCell>{product.productPrice} $</TableCell>
                                        <TableCell>{product.productQuantity}</TableCell>
                                        <TableCell>{(parseFloat(product.productPrice) * parseFloat(product.productQuantity)).toFixed(2)} $</TableCell>
                                    </TableRow>
                                ))}
                                <TotalRow>
                                    <TotalCostCell>Итого стоимость посылки:</TotalCostCell>
                                    <TotalCell>{parcel.totalCost} $</TotalCell>
                                </TotalRow>
                            </TableContainer>
                        </ExpandedInfo>
                    )}
                    <GreenStrip>
                        <CardHeaderLink href="#">{parcel.status}</CardHeaderLink>
                    </GreenStrip>
                </ParcelCard>
            ))}
        </>
    );
});
export default React.memo(ParcelCardComponent);