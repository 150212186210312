import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/MainCabinetHeader";
import { ReactComponent as SearchIcon } from "../images/search-icon.svg";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Footer from "../components/footers/MainFooterWithLinks";
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../FireBaseConfig';

// Import your own flag icons in PNG format
import USAFlag from "../images/us-icon.png";
import TurkeyFlag from "../images/tr-icon.png";

const GlobalStyle = createGlobalStyle`
    select, option {
        color: #000 !important;
        background-color: #fff !important;
    }
`;

const Container = styled.div`
    ${tw`relative w-full`}
    padding: 0;
    margin: 0;
    box-sizing: border-box;
`;

const TwoColumn = styled.div`
    ${tw`flex flex-col lg:flex-row lg:items-start max-w-screen-xl mx-auto py-20 md:py-24`}
    padding-bottom: 0;
`;

const LeftColumn = styled.div`
    ${tw`relative w-full text-left mx-auto`}
`;

const Heading = styled.h1`
    ${tw`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-4xl leading-tight`}
    margin-bottom: 20px;
    color: #2D2D2D;
`;

const Actions = styled.div`
    ${tw`relative flex items-center w-full `}
`;

const InputContainer = styled.div`
    ${tw`relative flex-grow flex items-center`}
    margin-right: 1rem;
`;

const SearchInput = styled.input`
    ${tw`pl-10 pr-4 py-3 rounded-full w-full font-medium`}
    height: 20px;
    border: 2px solid #0ABD19;

    &:hover {
        border: 2px solid #0ABD19;
    }

    &:focus {
        border: 2px solid #0ABD19;
    }
`;

const SearchIconContainer = styled.div`
    ${tw`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none`}
`;

const AddButton = styled.button`
    ${tw`bg-green-500 text-white font-bold py-2 px-4 rounded-full flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    height: 37px;
    background-color: #0ABD19;
    border: none;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);

    &:hover, &:focus {
        background-color: #025e07;
    }
`;

const InfoMessageBox = styled.div`
    ${tw`flex flex-col items-start p-4 bg-yellow-100 rounded-lg mt-8`}
    width: calc(100% - 2rem);
    max-width: 100%;
    color: #333;
    background-color: #fffbe5;
    border: 1px solid #f5e1a4;
`;

const InfoMessageHeading = styled.h2`
    ${tw`text-lg font-bold mb-2`}
`;

const InfoMessageText = styled.p`
    ${tw`text-base`}
`;

const BottomButtonsContainer = styled.div`
    ${tw`flex justify-start gap-4 mt-2`}
`;

const BottomButton = styled.button`
    ${tw`w-auto mt-6 bg-green-500 text-white font-bold py-4 px-6 rounded-full flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    background-color: #0ABD19;
    border: none;

    &:hover, &:focus {
        transform: scale(1.1);
    }
`;

const ServiceCard = styled.div`
    position: relative;
    margin-bottom: 20px;
    padding: 26px 31px;
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    background: #FEFBE1;
    display: grid;
    column-gap: 6%;
    justify-content: space-between;
    grid-template-columns: 33% 30% 27%;
    box-sizing: border-box;
    outline: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    color: #2D2D2D;
    font-family: 'Gilroy Medium';
`;

const ServiceCardText = styled.div`
    ${tw`flex-1`}
`;

const ServiceCardInfo = styled.div`
    ${tw`text-sm text-gray-600`}
`;

const CountryFlag = styled.img`
    ${tw`w-6 h-6 mr-2`}
`;

const HighlightedText = styled.span`
    ${tw`font-bold`}
    color: #0ABD19;
`;

const PriceContainer = styled.div`
    ${tw`flex flex-col justify-center`}
    color: #2D2D2D;
    font-family: 'Gilroy Medium';
`;

const ServiceName = styled.div`
    font-weight: bold;
    color: #2D2D2D;
`;

const Price = styled.div`
    color: #6c757d;
`;

const TotalPrice = styled.div`
    color: #28a745;
`;
const Link =styled.a `
text-decoration: none`
const renderFlag = (countryCode) => {
    const countryFlags = {
        'США': USAFlag,
        'Турция': TurkeyFlag
    };
    return <CountryFlag src={countryFlags[countryCode]} alt={countryCode} />;
};

export default ({ roundedHeaderButton }) => {
    const [showFirstImage, setShowFirstImage] = useState(true);
    const [services, setServices] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [parcelDetails, setParcelDetails] = useState({});

    useEffect(() => {
        const interval = setInterval(() => {
            setShowFirstImage((prev) => !prev);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const fetchServices = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const q = query(collection(db, 'applications'), where('userId', '==', user.uid));
                const querySnapshot = await getDocs(q);
                const servicesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setServices(servicesList);

                // Fetch parcel details
                const parcelDetailsPromises = servicesList.map(async (service) => {
                    const parcelDoc = await getDoc(doc(db, 'parcels', service.packageId));
                    return { id: service.packageId, data: parcelDoc.data() };
                });

                const parcelDetailsResults = await Promise.all(parcelDetailsPromises);
                const parcelDetailsMap = parcelDetailsResults.reduce((acc, parcel) => {
                    acc[parcel.id] = parcel.data;
                    return acc;
                }, {});
                setParcelDetails(parcelDetailsMap);
            }
        };
        fetchServices();
    }, []);

    const filteredServices = services.filter(service =>
        service.packageId.includes(searchQuery) ||
        service.comments.includes(searchQuery)
    );

    return (
        <>
            <GlobalStyle />
            <AnimationRevealPage>
                <Header roundedHeaderButton={roundedHeaderButton} />
                <Container>
                    <TwoColumn>
                        <LeftColumn>
                            <Heading>Мои услуги</Heading>
                            <Actions>
                                <InputContainer>
                                    <SearchIconContainer>
                                        <SearchIcon />
                                    </SearchIconContainer>
                                    <SearchInput
                                        type="text"
                                        placeholder="Поиск по номеру посылки, трекингу, названию посылки или товара"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </InputContainer>

                               <Link href="/ServisApplication"><AddButton>Добавить посылку</AddButton></Link>
                            </Actions>
                            {filteredServices.length > 0 ? (
                                filteredServices.map((service) => {
                                    const parcelDetail = parcelDetails[service.packageId] || {};
                                    return (
                                        <ServiceCard key={service.id}>
                                            <ServiceCardText>
                                                {parcelDetail.warehouse && renderFlag(parcelDetail.warehouse)}
                                                <HighlightedText>Посылка #{service.packageId}</HighlightedText>
                                                <ServiceCardInfo>
                                                    <div>Создана: {new Date(parcelDetail.createdAt).toLocaleString()}</div>
                                                    <div>Статус: {parcelDetail.status}</div>
                                                </ServiceCardInfo>
                                            </ServiceCardText>
                                            <ServiceName>
                                                Название услуги:
                                                <div>{service.service}</div>
                                            </ServiceName>
                                            <PriceContainer>
                                                <Price>Стоимость за услугу: 199 ₽ ($2.15)</Price>
                                                <TotalPrice>Итого стоимость за услуги: 199 ₽ ($2.15)</TotalPrice>
                                            </PriceContainer>
                                        </ServiceCard>
                                    );
                                })
                            ) : (
                                <InfoMessageBox>
                                    <InfoMessageHeading>У Вас пока нет добавленных услуг!</InfoMessageHeading>
                                    <InfoMessageText>Если вам необходимо добавить услугу - нажмите на соответствующую кнопку, выберите нужную услугу и добавьте посылку, к которой необходимо применить выбранную Вами услугу.</InfoMessageText>
                                </InfoMessageBox>
                            )}
                            <BottomButtonsContainer>
                                <Link href="/PersonalArea"> <BottomButton>В профиль</BottomButton></Link>
                            </BottomButtonsContainer>
                        </LeftColumn>
                    </TwoColumn>
                </Container>
                <Footer />
            </AnimationRevealPage>
        </>
    );
};
