import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Step4and5 } from "components/misc/Headings.js";
import { useParams } from "react-router-dom";
import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../FireBaseConfig";

const HighlightedText = styled.span`
    ${tw`text-primary-500`};
    color: #0ABD19;
`;

const FormContainer = styled.div`
    ${tw`flex justify-center items-center flex-grow`};
`;

const StepTitle = styled(Step4and5)`
    ${tw`w-full mt-2 mb-6`};
`;

const BottomButton = styled.button`
    ${tw`w-auto text-white font-bold rounded-lg flex items-center justify-center leading-none focus:outline-none transition duration-300`};
    background-color: ${(props) => (props.active ? "#0ABD19" : "#d3d3d3")}; /* Цвет активной и неактивной кнопки */
    border: none;
    width: 220px;
    height: 40px;
    margin-top: 16px; /* Отступ от текста */

    &:hover, &:focus {
        transform: ${(props) => (props.active ? "scale(1.1)" : "none")}; /* Увеличение кнопки только если активна */
    }
`;

const StepRowContainer = styled.div`
    ${tw`grid grid-cols-2 gap-12 max-w-6xl mx-auto mb-8`};
    padding-top: 30px;
`;

const StepContainer = styled.div`
    ${tw`w-full p-8 bg-white shadow-lg rounded-lg border`};
    border: 2px solid #1BA557;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    width: 550px;
`;

const StepText = styled.p`
    ${tw`text-base text-gray-600`};
    margin-top: 8px;
`;

const BoldGreenText = styled.h3`
    ${tw` font-bold text-lg`}
    color: #0ABD19;
`;

const Step4Container = styled.div`
    margin-left: -50px;
    text-align: left;
`;

const Step5Container = styled.div`
    text-align: left;
`;

const Step4and5Step = () => {
    const { id } = useParams();
    const [totalCostUSD, setTotalCostUSD] = useState(0); // Общая стоимость в долларах
    const [totalCostKZT, setTotalCostKZT] = useState(0); // Общая стоимость в тенге
    const [insuranceAmount, setInsuranceAmount] = useState(0); // Сумма страхования в тенге
    const [usdToKztRate, setUsdToKztRate] = useState(0); // Курс доллара к тенге
    const [buttonActive, setButtonActive] = useState(false); // Состояние кнопки

    useEffect(() => {
        // Получаем курс доллара к тенге
        const fetchExchangeRate = async () => {
            try {
                const response = await fetch(`https://openexchangerates.org/api/latest.json?app_id=dcdd8eb0771e4dffa7bdf44d9637fc6f`);
                const data = await response.json();
                const rate = data.rates.KZT; // Курс доллара к тенге
                setUsdToKztRate(rate);
                console.log("USD to KZT rate:", rate);
            } catch (error) {
                console.error("Ошибка при получении курса валют:", error);
            }
        };

        // Функция для получения данных о посылке
        const fetchParcelData = async (parcelId) => {
            const cleanId = parcelId.trim();
            console.log(`Fetching parcel with ID: ${cleanId}`);

            try {
                // Запрос к Firestore для поиска посылки по полю "id"
                const q = query(collection(db, 'parcels'), where('id', '==', cleanId));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    querySnapshot.forEach((doc) => {
                        const parcelData = doc.data();
                        console.log("Parcel Data:", parcelData);

                        // Получаем список продуктов и считаем общую стоимость товаров в USD
                        const products = parcelData.products || [];
                        const totalUSD = products.reduce((acc, product) => acc + parseFloat(product.productPrice), 0);

                        // Конвертируем сумму в тенге с помощью текущего курса
                        const totalKZT = totalUSD * usdToKztRate;

                        // Вычисляем сумму страхования как 3% от общей стоимости товаров в тенге
                        const insurance = totalKZT * 0.03;

                        // Устанавливаем стейты
                        setTotalCostUSD(totalUSD);
                        setTotalCostKZT(totalKZT);
                        setInsuranceAmount(insurance);
                    });
                } else {
                    console.error(`No parcel found with this ID: ${cleanId}`);
                }
            } catch (error) {
                console.error("Error fetching parcel:", error);
            }
        };

        if (id) {
            fetchExchangeRate(); // Получаем курс валют
            fetchParcelData(id); // Получаем данные о посылке
        }
    }, [id, usdToKztRate]);

    const handleButtonClick = () => {
        setButtonActive(!buttonActive); // Меняем состояние кнопки
    };

    return (
        <FormContainer>
            <StepRowContainer>
                {/* Шаг 4 */}
                <Step4Container>
                    <StepTitle active={true}>
                        <HighlightedText>Шаг 4. </HighlightedText>Обязательное страхование посылки
                    </StepTitle>
                    <StepContainer>
                        <BoldGreenText>Сумма страхования - {insuranceAmount.toFixed(2)} ₸</BoldGreenText>
                        <StepText>
                            В стоимость доставки будет включено страхование — 3% от объявленной стоимости товаров.
                        </StepText>
                        <BottomButton active={buttonActive} onClick={handleButtonClick}>
                            Добавить
                        </BottomButton>
                    </StepContainer>
                </Step4Container>

                {/* Шаг 5 */}
                <Step5Container>
                    <StepTitle active={true}>
                        <HighlightedText>Шаг 5. </HighlightedText>Таможенный лимит
                    </StepTitle>
                    <StepContainer>
                        <BoldGreenText>Таможенная пошлина - 0 ₽</BoldGreenText>
                        <StepText>Стоимость товаров в посылке: {totalCostKZT.toFixed(2)} ₸</StepText>
                        <StepText>Беспошлинный лимит РФ на одну посылку 200 €. </StepText>
                    </StepContainer>
                </Step5Container>
            </StepRowContainer>
        </FormContainer>
    );
};

export default Step4and5Step;
