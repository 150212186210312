import React, {useEffect, useState} from "react";
import tw, {styled} from "twin.macro";
import {ExpecteLink} from "../components/misc/Headings";
import {useParams} from "react-router-dom";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../FireBaseConfig";

const FormContainer = styled.div`
    ${tw`flex justify-center items-center flex-grow`};
`;
const PageTitle = styled.h1`
    ${tw`text-3xl font-bold `};
    text-align: left;
`;
const HighlightedText = styled.span`
    ${tw`text-primary-500`};
    color: #0ABD19;
`;
const Container = styled.div`
    ${tw`w-full max-w-6xl p-8 bg-white shadow-lg rounded-lg border`};
    border: 2px solid #1BA557;
    border-radius: 15px;
    padding: 40px;
    display: grid;
    margin-top: 0px;
    grid-template-columns: 1fr 1fr; /* Two columns */
    column-gap: 60px; /* Bigger gap between columns for better spacing */
`;

const Column = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
`;
const ColumnLeft = styled.div`
    ${tw`text-gray-600 font-semibold`};
    text-align: left;
`;

const ColumnRight = styled.div`
    ${tw`text-gray-900 font-normal`};
    text-align: right;
`;


const StepTitle = tw(ExpecteLink)`w-full mt-2 mb-4`;
const Step1 = () => {
    const [parcelData, setParcelData] = useState(null);
    const { id } = useParams(); // Получаем id из URL
    const [loading, setLoading] = useState(true); // Изначально загружаем

    async function fetchParcelData(parcelId) {
        const cleanId = parcelId.trim();
        console.log(`Fetching parcel with ID: ${cleanId}`);

        try {
            const q = query(collection(db, 'parcels'), where('id', '==', cleanId));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    setParcelData(doc.data());
                });
            } else {
                console.error(`No parcel found with this ID: ${cleanId}`);
            }
        } catch (error) {
            console.error("Error fetching parcel:", error);
        } finally {
            setLoading(false); // Убираем состояние загрузки
        }
    }

    useEffect(() => {
        // Получение данных о посылке при изменении id
        if (id) {
            setLoading(true); // Активируем состояние загрузки
            fetchParcelData(id);
        }
    }, [id]);

    return (
        <FormContainer>
            <div style={{width: '100%', maxWidth: '1280px'}}>
                <PageTitle active={true}>Заявка на отправку посылки в Казахстан</PageTitle>
                <StepTitle active={true}>
                    <HighlightedText>Шаг 1. </HighlightedText>Проверьте состав посылки
                </StepTitle>
                {loading ? (
                    <div>Загрузка...</div>
                ) : parcelData ? (
                    <Container>
                        <div>
                            <Column>
                                <ColumnLeft>Номер посылки:</ColumnLeft>
                                <ColumnRight>{parcelData.id}</ColumnRight>
                            </Column>
                            <Column>
                                <ColumnLeft>Входящий трэк №:</ColumnLeft>
                                <ColumnRight>{parcelData.trackingNumber}</ColumnRight>
                            </Column>
                            <Column>
                                <ColumnLeft>Наименование:</ColumnLeft>
                                <ColumnRight>{parcelData.parcelName}</ColumnRight>
                            </Column>
                        </div>
                        <div>
                            <Column>
                                <ColumnLeft>Стоимость товаров:</ColumnLeft>
                                <ColumnRight>{parcelData.totalCost}</ColumnRight>
                            </Column>
                            <Column>
                                <ColumnLeft>Количество товаров в посылке:</ColumnLeft>
                                <ColumnRight>{parcelData.totalQuantity}</ColumnRight>
                            </Column>
                            <Column>
                                <ColumnLeft>Вес посылки:</ColumnLeft>
                                <ColumnRight>{parcelData.width}</ColumnRight>
                            </Column>
                            <Column>
                                <ColumnLeft>Габариты (В*Ш*Д):</ColumnLeft>
                                <ColumnRight>
                                    {parcelData.height} * {parcelData.width} * {parcelData.length}
                                </ColumnRight>
                            </Column>
                        </div>
                    </Container>
                ) : (
                    <div>Нет данных о посылке</div>
                )}
            </div>
        </FormContainer>
    );
};

export default Step1;