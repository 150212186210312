import React, { useState } from "react";
import styled from "styled-components/macro";
import tw from "twin.macro";
import Arrow from "images/icon/GreenSmallArrow.svg";
import BoxIcon from "images/icon/cardboard-box_11530648.png"; // Ensure you have the BoxIcon PNG file

const SidebarContainer = styled.div`
    ${tw`bg-white shadow-lg`}
    width: 250px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    height: 100vh; /* Full height of the page */

    @media (max-width: 768px) {
        width: 200px;
    }
`;

const SidebarItem = styled.div`
    ${tw`flex flex-col mb-4 `}
    text-decoration: none;
`;

const SidebarItemTitle = styled.div`
    ${tw`flex justify-between items-center cursor-pointer p-2 text-gray-800 font-semibold`}
    &:hover {
        background-color: #f1f1f1;
    }
`;

const SidebarItemIconText = styled.div`
    ${tw`flex items-center`}
`;

const SidebarSubItem = styled.a`
    ${tw`flex items-center pl-8 text-gray-600 cursor-pointer my-2`}
    text-decoration: none;
    &:hover {
        color: #0ABD19;
    }
`;

const Icon = styled.img`
    ${tw`mr-1`} /* Adjusted the margin to ensure the text is closer to the icon */
    width: 25px;  // Increased the size of the icon
    height: 25px; // Increased the size of the icon
`;

const SidebarToggleIcon = styled.img.attrs({ src: Arrow })`
    ${tw`ml-2 transition-transform duration-300`}
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const SidebarMenu = ({ title, items }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <SidebarItem>
            <SidebarItemTitle onClick={() => setIsOpen(!isOpen)}>
                <SidebarItemIconText>
                    <Icon src={BoxIcon} />
                    {title}
                </SidebarItemIconText>
                <SidebarToggleIcon isOpen={isOpen} />
            </SidebarItemTitle>
            {isOpen && items.map((item, index) => (
                <SidebarSubItem href={item.href} key={index}>
                    <Icon src={BoxIcon} />
                    {item.name}
                </SidebarSubItem>
            ))}
        </SidebarItem>
    );
};

const Sidebar = () => {
    return (
        <SidebarContainer>
            <SidebarMenu title="Клиент" items={[
                { name: "Подпункт 1", href: "/subpage1" },
                { name: "Подпункт 2", href: "/subpage2" },
                { name: "Подпункт 3", href: "/subpage3" }
            ]} />
            <SidebarMenu title="Склад" items={[
                { name: "Прием посылок", href: "/WareHouseMainPage" },
                { name: "Ожидаемые посылки", href: "/ExpectedParcels" },
                { name: "Редактировать посылку", href: "/ProccessingParcels" },
                { name: "Входящие", href: "/IncomingPParcels" },
                { name: "Обработанные", href: "/Parsed" },
                { name: "Оплаченные посылки", href: "/paid" },
                { name: "Неизвестные посылки", href: "/unknown" },
                { name: "Возврат в магазин", href: "/return" },
                { name: "Таможенное оформление", href: "/customs" },
                { name: "Поврежденные посылки", href: "/damaged" },
                { name: "Услуги склада", href: "/services" },

            ]} />
            <SidebarMenu title="Доставка" items={[
                { name: "Подпункт 1", href: "/subpage4" },
                { name: "Подпункт 2", href: "/subpage5" },
                { name: "Подпункт 3", href: "/subpage6" }
            ]} />
            <SidebarItemTitle>
                <SidebarItemIconText>
                    <Icon src={BoxIcon} alt="icon" />
                    Поиск
                </SidebarItemIconText>
            </SidebarItemTitle>
        </SidebarContainer>
    );
};

export default Sidebar;
