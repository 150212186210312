import React, { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import tw from "twin.macro";
import HeaderContainer from "components/headers/WarehouseAccountingHeader";
import Arrow from "images/icon/GreenSmallArrow.svg";
import Sidebar from "WarehouseAccounting/components/Sidebar";
import { useNavigate } from "react-router-dom";
import BoxIcon from "images/icon/cardboard-box_11530648.png";
import { collection, addDoc, query, where, updateDoc, getDocs } from "firebase/firestore";
import { db } from "../FireBaseConfig";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'SFUIText';
        font-weight: 300;
        font-style: normal;
    }

    body, html {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'SFUIText', sans-serif;
    }
`;

const Container = styled.div`
    ${tw`min-h-screen`}
    display: flex;
    flex-direction: column;
`;

const MainContent = styled.div`
    ${tw`flex flex-col lg:flex-row`}
    flex-grow: 1;
`;


const SidebarItem = styled.div`
    ${tw`flex flex-col mb-4`}
`;

const Table = styled.table`
    ${tw`w-full mt-8`}
    border-collapse: collapse;
`;

const TableHeader = styled.th`
    ${tw`border border-gray-300 p-2 text-left`}
`;

const TableRow = styled.tr`
    ${tw`border-b border-gray-200`}
`;

const TableCell = styled.td`
    ${tw`border border-gray-300 p-2`}
`;

const SidebarItemTitle = styled.div`
    ${tw`flex justify-between items-center cursor-pointer p-2 text-gray-800 font-semibold`}
    &:hover {
        background-color: #f1f1f1;
    }
`;

const SidebarItemIconText = styled.div`
    ${tw`flex items-center`}
`;

const SidebarSubItem = styled.div`
    ${tw`flex items-center pl-8 text-gray-600 cursor-pointer my-2`}
    &:hover {
        color: #0ABD19;
    }
`;

const Icon = styled.img`
    ${tw`mr-1`}
    width: 25px;
    height: 25px;
`;

const Content = styled.div`
    ${tw`flex-grow p-8`}
    display: flex;
    flex-direction: column;
`;

const Heading = styled.h1`
    ${tw`text-green-500 font-semibold text-xl`}
`;

const Divider = styled.div`
    ${tw`bg-gray-300`}
    height: 1px;
    width: 100%;
    margin: 20px 0;
`;

const Form = styled.form`
    ${tw`flex items-center space-x-6`}
    gap: 1rem;
`;

const Label = styled.label`
    ${tw`text-sm font-semibold mb-4`}
    color: #d3d3d3;
`;

const InputContainer = styled.div`
    ${tw`flex flex-col `}
    flex: 1;
`;

const Input = styled.input`
    ${tw`border-2 rounded-lg p-3 w-full text-gray-600 `}
    border-color: #D3D3D3;

    &:hover, &:focus {
        border-color: #0ABD19;
    }
`;

const Select = styled.select`
    ${tw`border-2 rounded-lg p-3 w-full text-gray-600`}
    border-color: #d3d3d3;
    &:hover, &:focus {
        border-color: #0ABD19;
    }
`;

const Button = styled.button`
    ${tw`text-white font-bold py-4 px-12 rounded-lg mt-8`}
    border: none;
    background-color: #0ABD19;
    &:hover, &:focus {
        background-color: #0ABD19;
    }
`;

const SidebarToggleIcon = styled.img.attrs({ src: Arrow })`
    ${tw`ml-2 transition-transform duration-300`}
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;



const MainPage = () => {
    const [currentDate, setCurrentDate] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [trackingId, setTrackingId] = useState('');
    const [packages, setPackages] = useState([]);
    const trackingIdRef = useRef(null);

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setCurrentDate(formattedDate);
    }, []);

    useEffect(() => {
        trackingIdRef.current.focus();
    }, [selectedService]);

    const processPackage = async () => {
        if (selectedService && trackingId) {
            const q = query(collection(db, "parcels"), where("trackingNumber", "==", trackingId));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;
                await updateDoc(docRef, {
                    status: "На складе",
                    dateReceived: currentDate
                });
                alert("Status updated to 'Обработанные' for the parcel with tracking number: " + trackingId);

                // Проверка на наличие уже добавленного trackingId в state packages
                const isPackageAlreadyAdded = packages.some(pkg => pkg.trackingId === trackingId);
                if (!isPackageAlreadyAdded) {
                    const updatedPackage = {
                        service: selectedService,
                        trackingId: trackingId,
                        date: currentDate,
                    };
                    setPackages([...packages, updatedPackage]);
                }
            } else {
                const newPackage = {
                    service: selectedService,
                    trackingId: trackingId,
                    date: currentDate,
                };
                try {
                    await addDoc(collection(db, "receivedPackages"), newPackage);

                    // Проверка на наличие уже добавленного trackingId в state packages
                    const isPackageAlreadyAdded = packages.some(pkg => pkg.trackingId === trackingId);
                    if (!isPackageAlreadyAdded) {
                        setPackages([...packages, newPackage]);
                    }
                } catch (error) {
                    console.error("Error adding document: ", error);
                }
            }
            setTrackingId('');
            trackingIdRef.current.focus();
        }
    };


    const handleAddPackage = async (e) => {
        e.preventDefault();
        await processPackage();
    };

    const handleScannerInput = async (e) => {
        if (e.key === 'Enter') {
            await processPackage();
        }
    };

    return (
        <Container>
            <GlobalStyle />
            <HeaderContainer />
            <MainContent>
                <Sidebar/>
                <Content>
                    <Heading>Приём посылок</Heading>
                    <Divider />
                    <Form onSubmit={handleAddPackage}>
                        <InputContainer>
                            <Label>Выберите службу доставки</Label>
                            <Select value={selectedService} onChange={(e) => setSelectedService(e.target.value)}>
                                <option value="">Выберите службу</option>
                                <option value="Amazon">Amazon</option>
                                <option value="UPS">UPS</option>
                                <option value="FedEx">FedEx</option>
                                <option value="DHL">DHL</option>
                                <option value="Lazership">Lazership</option>
                                <option value="USPS">USPS</option>
                                <option value="Other">Other</option>
                            </Select>
                        </InputContainer>
                        <InputContainer>
                            <Label>Дата</Label>
                            <Input type="text" value={currentDate} readOnly />
                        </InputContainer>
                        <InputContainer>
                            <Label>Tracking ID</Label>
                            <Input
                                placeholder="Введите ID"
                                value={trackingId}
                                onChange={(e) => setTrackingId(e.target.value)}
                                ref={trackingIdRef}
                                onKeyPress={handleScannerInput}
                            />
                        </InputContainer>
                        <Button type="submit">Add Package</Button>
                    </Form>
                    {packages.length > 0 && (
                        <Table>
                            <thead>
                            <tr>
                                <TableHeader>Служба доставки</TableHeader>
                                <TableHeader>Tracking ID</TableHeader>
                                <TableHeader>Дата</TableHeader>
                            </tr>
                            </thead>
                            <tbody>
                            {packages.map((pkg, index) => (
                                <TableRow key={index}>
                                    <TableCell>{pkg.service || "N/A"}</TableCell>
                                    <TableCell>{pkg.trackingId || "N/A"}</TableCell>
                                    <TableCell>{pkg.date || "N/A"}</TableCell>
                                </TableRow>
                            ))}
                            </tbody>
                        </Table>
                    )}
                </Content>
            </MainContent>
        </Container>
    );
};

export default MainPage;
