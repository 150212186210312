import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/login-illustration.svg";
import logo from "images/logo.svg";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "FireBaseConfig";

const Container = styled(ContainerBase)`
    ${tw`min-h-screen bg-white text-white font-medium flex justify-center -m-8`}
`;

const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow-xl sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto w-full max-w-sm`;
const Input = styled.input`
    ${tw`w-full px-4 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-8 first:mt-12`}
`;

const SubmitButton = styled.button`
    ${tw`mt-8 tracking-wide font-semibold text-gray-100 py-3 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
    background-color: #0ABD19;
    border: none;
    width: 100%;
    &:hover {
        background-color: #067D10;
    }
`;

const IllustrationContainer = styled.div`
    ${tw`sm:rounded-r-lg flex-1 text-center hidden lg:flex justify-center`}
    background-color: rgb(255, 255, 255);
`;
const IllustrationImage = styled.div`
    ${props => `background-image: url("${props.imageSrc}");`}
    ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

const ErrorText = tw.p`text-sm text-red-600 text-center mt-4`;
const SuccessText = tw.p`text-sm text-green-600 text-center mt-4`;

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const handleSendPasswordResetEmail = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");
        try {
            await sendPasswordResetEmail(auth, email);
            setSuccess("Ссылка для сброса пароля отправлена на вашу почту.");
        } catch (error) {
            console.error("Ошибка отправки ссылки для сброса пароля:", error.message);
            setError("Не удалось отправить ссылку. Проверьте правильность email.");
        }
    };

    return (
        <AnimationRevealPage>
            <Container>
                <Content>
                    <MainContainer>
                        <LogoLink href="#">
                            <LogoImage src={logo} />
                        </LogoLink>
                        <MainContent>
                            <Heading>Восстановление пароля</Heading>
                            <FormContainer>
                                {error && <ErrorText>{error}</ErrorText>}
                                {success && <SuccessText>{success}</SuccessText>}
                                <Form onSubmit={handleSendPasswordResetEmail}>
                                    <Input
                                        type="email"
                                        placeholder="Введите вашу почту"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <SubmitButton type="submit">
                                        <span className="text">Отправить ссылку для сброса</span>
                                    </SubmitButton>
                                </Form>
                            </FormContainer>
                        </MainContent>
                    </MainContainer>
                    <IllustrationContainer>
                        <IllustrationImage imageSrc={illustration} />
                    </IllustrationContainer>
                </Content>
            </Container>
        </AnimationRevealPage>
    );
};

export default ForgotPassword;
