import React from 'react';
import Step3Backend from "./STEP3";
import tw from 'twin.macro';
import styled from 'styled-components';
import AnimationRevealPage from '../helpers/AnimationRevealPage';
import MainCabinetHeader from '../components/headers/MainCabinetHeader';
import Step1 from "./step1"
import Step2 from "./STEP2"
import Step4and5Step from "./Step4and5";


const PageContainer = styled.div`
    ${tw`min-h-screen flex flex-col`};
`;


const ExpectedLink = () => {


    return (
        <AnimationRevealPage>
            <MainCabinetHeader/>
            <PageContainer>
                <Step1/>
                <Step2/>
                <Step3Backend/>
                <Step4and5Step/>
            </PageContainer>
        </AnimationRevealPage>
    );
};

export default ExpectedLink;